import React from "react";

import Box from "components/Box";

import PageSectionDocumentList from "./pageSectionDocumentList";

const style = {
  container: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "repeat(2, minmax(0, 1fr))"],
    gap: 4,
    width: "100%",
    maxWidth: "xl",
    mx: "auto",
    mb: 6,
    px: 3,
  },
  list: {
    mb: 0,
    px: 0,
  },
};

const DocumentLists = ({ lists }) => {
  return (
    <Box sx={style.container}>
      {lists.map(list => (
        <PageSectionDocumentList key={list._key} sx={style.list} {...list} />
      ))}
    </Box>
  );
};

export default DocumentLists;
